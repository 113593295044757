import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-50px">
          <CFImage src={mobileAbout} w="100%" alt="Sushi Musa About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          alignCenter
          w="100%"
          maxWidth="2000px"
          mb="50px"
        >
          <CFImage src={about} w="82%" alt="Promotions" m="0 auto" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
